<template>
  <!-- Search Filter -->
  <div class="row filter-row mb-4">
    <div class="col-sm-6 col-md-4">
      <div class="input-block mb-3 form-focus" :class="{ focused: isFocused }">
        <input
          class="form-control floating"
          v-model="employee_id"
          @focus="isFocused = true"
          @blur="isFocused = employee !== ''"
          type="text"
          @keyup.enter="filterData"
        />
        <label class="focus-label">Employee</label>
      </div>
    </div>
    <div class="col-sm-6 col-md-4">
      <div class="input-block mb-3 form-focus select-focus">
        <div class="h-100">
          <a-select
              v-model:value="value"
              show-search
              placeholder="Select a Department"
              style="width: 100%;"
              :options="optionsEmpDepartment"
              @change="handleChange"
            >
          </a-select>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4">
      <div class="input-block mb-3 form-focus">
        <div class="h-100">
          <a-select
              v-model:value="employee_status"
              show-search
              placeholder="Employee Status"
              style="width: 100%;"
              :options="empStatus"
              @change="handleEmpStatusChange"
            >
          </a-select>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4">
      <div class="input-block mb-3 form-focus">
        <div class="cal-icon">
          <datepicker
            v-model="start_date"
            :placeholder="$route.name == 'export-attendance'? 'Start Date' : 'Joining From'"
            class="form-control floating datetimepicker"
            :editable="true"
            :clearable="false"
            :input-format="dateFormat"
          />
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4">
      <div class="input-block mb-3 form-focus">
        <div class="cal-icon">
          <datepicker
            v-model="end_date"
            :placeholder="$route.name == 'export-attendance'? 'End Date' : 'Joining From'"
            class="form-control floating datetimepicker"
            :editable="true"
            :clearable="false"
            :input-format="dateFormat"
          />
        </div>
      </div>
    </div>
    
    <div class="col-sm-6 col-md-2">
      <div class="d-grid">
        <a href="javascript:;" class="btn btn-success" @click="filterData"> Search </a>
      </div>
    </div>
    <div class="col-sm-6 col-md-2">
      <div class="d-grid">
        <a href="javascript:;" class="btn btn-danger" @click="clearData"> Clear </a>
      </div>
    </div>
  </div>
  <!-- /Search Filter -->
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
import axios from 'axios';
import { notification } from "ant-design-vue";
import moment from 'moment';
export default {
  emits:['filter-data'],
  props:{
    hasEmployeeStatus: {
      type: [Boolean],
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      select2: null,
      employeereportdepartment: [
        "Select Department",
        "Designing",
        "Development",
        "Finance",
        "Hr & Finance",
      ],
      startdate: currentDate,
      dateFormat: 'dd-MM-yyyy',
      startdateone: currentDateOne,
      employee: "",
      isFocused: false,
      departments:[],
      optionsEmpDepartment:[],
      value:null,
      department_id:null,
      employee_status:'',
      employee_id:null,
      start_date:null,
      end_date:null,
      empStatus:[
        {
          label:'All',
          value:''
        },
        {
          label:'Active',
          value:'1'
        },
        {
          label:'In Active',
          value:'0'
        },
      ]
    };
  },
  methods:{
    handleChange(value)
    {
      this.$nextTick(()=>{
      this.department_id = value;
        this.filterData()
      })
    },
    handleEmpStatusChange(value)
    {
      this.$nextTick(()=>{
      this.employee_status = value;
        this.filterData()
      })
    },
    filterData()
    {
      let search_filter = {
        department_id : this.department_id || null,
        employee_id : this.employee_id || null,
        start_date : this.start_date ? moment(this.start_date).format('YYYY-MM-DD') : null,
        end_date : this.end_date ? moment(this.end_date).format('YYYY-MM-DD') : null,
        employee_status: this.employee_status != '' ? this.employee_status : null
      }
      this.$emit('filter-data',search_filter);
    },
    clearData()
    {
      this.value = null
      this.employee_id = null
      this.start_date = null
      this.end_date = null
      this.employee_status = null

      let search_filter = {
        department_id : null,
        employee_id : null,
        start_date : null,
        end_date : null,
        employee_status: null
      }
      this.$emit('filter-data',search_filter);
    }
  },
  mounted(){
    var token = window.localStorage.getItem("token");
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.get('departments/all').then(response => {
      if(response?.data?.data && response?.data?.data?.length)
      {
        const placeholder = [{id:'',name:'All'}];
        this.departments = [...placeholder,...response?.data?.data];
      }
      else
      {
        this.departments = [{id:'',name:'No Records'}];
      }

      this.optionsEmpDepartment= this.departments.map(department => {
        return {
            label: department.name,
            value: department.id
        };
      });

    }).catch(error => {
      if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
            
        localStorage.clear();
        
        notification.open({
            message: 'Please Login',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
          
        this.$router.push({name: 'login'}).catch(error => {}) 
        
      }else{
        
        this.errorMessage = error.message;
        notification.open({
            message: response.data.message,
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
          
      }
    })
  }
};
</script>
