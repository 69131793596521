<template>
    <div class="main-wrapper">
      <layout-header></layout-header>
      <layout-sidebar></layout-sidebar>
      <div class="page-wrapper" ref="formContainer">
            <div class="content container-fluid">
                 <!-- Page Header -->
                    <inbox-header title="Teams" path="Teams" text="Teams" text1="Add Team" :hasCreatePermission="true" @reset-data="clearFormData" />
                <!-- /Page Header -->
              <div class="row">
                  <div class="col-md-12">
                      <div class="showentries mb-3">
                      <label
                          >Show
                          <select v-model="pagination.pageSize" @change="updatePerPage({
                            params: { per_page: this.pagination.pageSize }
                          })">
                            <option value="2">2</option>
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          </select>
                          entries</label
                      >
                      </div>
                      <div class="table-responsive">
                          <a-table
                              class="stripped table-hover"
                              :columns="columns"
                              :data-source="data"
                              :pagination="pagination"
				             @change="handleTableChange"
                          >
                              <template #bodyCell="{ column, record }">
                                  <template v-if="column.key === 'team_status'">
                                    {{ record?.team_status == 1 ? 'Active' : record?.team_status == 0 ? 'In Active' : '-'  }}
                                  </template>
                                  <template v-if="column.key === 'Action'">
                                      <div class="dropdown dropdown-action" v-if="hasEditPermission || hasDeletePermission">
                                          <a
                                              href="javascript:void(0);"
                                              class="action-icon dropdown-toggle"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                              ><i class="material-icons">more_vert</i></a
                                          >
                                          <div class="dropdown-menu dropdown-menu-right">
                                              <a v-if="hasEditPermission"
                                              @click="editData(record)"
                                              class="dropdown-item"
                                              href="javascript:void(0);"
                                              data-bs-toggle="modal"
                                              data-bs-target="#add_teams"
                                              ><i class="fa-solid fa-pencil m-r-5"></i> Edit</a
                                              >
                                              <a v-if="hasDeletePermission"
                                              class="dropdown-item"
                                              href="javascript:void(0);"
                                              @click="activeData = record"
                                              data-bs-toggle="modal"
                                              data-bs-target="#delete_wages"
                                              ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
                                              >
                                          </div>
                                      </div>
                                  </template>
                              </template>
                              
                          </a-table>
                      </div>
                  </div>
              </div>
            </div>
        </div>
    </div>
    <!-- Delete Project Modal -->
  <div class="modal custom-modal fade" id="delete_wages" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Delete Team</h3>
            <p>Are you sure want to delete?</p>
          </div>
          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <a
                  href="javascript:void(0);"
                  :disabled="disableButton"
                  class="btn btn-primary continue-btn"
				  @click="deleteTeamAction"
				  >Delete</a
                >
              </div>
              <div class="col-6">
                <a
                  href="javascript:void(0);"
                  data-bs-dismiss="modal"
                  class="btn btn-primary cancel-btn"
				          ref="deleteTeamclose"
                  >Cancel</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Wages Modal -->

    <!-- Update Team Modal -->
    <div
    id="add_teams"
    class="modal custom-modal fade"
    role="dialog"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Team Information</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref="modal_team_information_close"
          >
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
                <div class="col-md-12">
                    <div class="input-block mb-3">
                        <label class="col-form-label"
                        >Team Name<span class="text-danger">*</span></label
                        >
                        <input type="text" class="form-control" v-model="editActiveData.team_name"/>
                        <span class="text-danger" v-if="errors && errors?.team_name && errors?.team_name != null">{{ errors?.team_name[0]  }}</span>
                    </div>
                    <div class="input-block mb-3">
                    <label class="col-form-label"
                    >Team Status</label>
                    <select class="form-select form-select-lg" v-model="editActiveData.team_status">
                        <option value="1">Active</option>
                        <option value="0">InActive</option> 
                    </select>
                    <span class="text-danger" v-if="errors && errors?.team_status && errors?.team_status != null">{{ errors?.team_status[0]  }}</span>
                    </div>
                    <div class="input-block mb-3 leave-duallist">
                    <label class="col-form-label">Add Team Members</label>
                    <div class="row">
                        <div class="col-lg-5 col-sm-5">
                        <select
                            v-model="selectedFrom"
                            class="form-control form-select"
                            size="7"
                            multiple
                        >
                        
                            <option
                            v-for="option in employees"
                            :value="option.id"
                            :key="option.id"
                            >
                            {{ option.first_name }} {{ option.last_name }}
                            </option>
                        </select>
                        </div>
                        <div class="multiselect-controls col-lg-2 col-sm-2 d-grid gap-2">
                        <button type="button" @click="moveRightAll" class="btn w-100 btn-white">
                            <i class="fa fa-forward"></i>
                        </button>
                        <button
                            type="button"
                            @click="moveRightSelected"
                            class="btn w-100 btn-white"
                        >
                            <i class="fa fa-chevron-right"></i>
                        </button>
                        <button
                            type="button"
                            @click="moveLeftSelected"
                            class="btn w-100 btn-white"
                        >
                            <i class="fa fa-chevron-left"></i>
                        </button>
                        <button type="button" @click="moveLeftAll" class="btn w-100 btn-white">
                            <i class="fa fa-backward"></i>
                        </button>
                        </div>
                        <div class="col-lg-5 col-sm-5">
                        <select
                            v-model="selectedTo"
                            class="form-control form-select"
                            size="8"
                            multiple
                        >
                            <option
                            v-for="option in optionsTo"
                            :value="option.id"
                            :key="option.id"
                            >
                            {{ option.first_name }} {{ option.last_name }}
                            </option>
                        </select>
                        </div>
                    </div>
                    </div>
                </div>
          </div>
          <div class="submit-section">
            <button class="btn btn-primary submit-btn" @click="updateTeamAction()" :disabled="disableButton">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
 <!-- Update Wage Modal -->
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { notification } from "ant-design-vue";
import axios from 'axios';

var pagination = {total: 0,
	    current: 1,
	    pageSize: 10,};

const columns = [
  {
    title: "Index",
    dataIndex: "id",
    key: "id",
    customRender: ({ index }) => {
		  return pagination.current === 1 ? index + 1 : (pagination.current - 1) * pagination.pageSize + (index + 1);
	  },
  },
  {
    title: "Team Name",
    dataIndex: "team_name",
    key: "team_name",
    sorter: {
      compare: (a, b) => {
        a = a.team_name.toLowerCase();
        b = b.team_name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "team_status",
    key: "team_status",
    sorter: {
      compare: (a, b) => {
        a = a.team_status.toLowerCase();
        b = b.team_status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "Action",
    sorter: true,
  },
];

  export default {
  data() {
    return {
        columns,
        data:null,
        activeData:{},
        editActiveData:{
            team_name:'',
            team_status:1
        },
        editData_:null,
        errors:[],
        disableButton:false,
        perpage: 10,
        pagination: pagination,
        hasEditPermission:true,
        hasDeletePermission:true,
        employeesCopy:[],
        employees:[],
        selectedTo: [],
        optionsTo: [],
        selectedFrom:[]
    }
  },
  methods: {
    ...mapActions(['fetchWages','deleteWages','updateWages']),
    handleTableChange(pagesize){
		
		var params = {
		   params: { per_page: pagesize.pageSize,page:pagesize.current }
		};
		
		this.updatePerPage(params);
		
	},
    async deleteTeamAction(){

        this.disableButton = true;
        if(this.activeData?.id)
        {
            var token = window.localStorage.getItem("token");
            axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;

            let loader = this.$loading.show({
                container: this.$refs.formContainer,
                canCancel: false
            });

            await axios.delete('/team/'+this.activeData?.id).then(response => {
                loader.hide();
                this.$refs.deleteTeamclose.click()
                this.updatePerPage();
                this.activeData = null

                this.disableButton = false;
                notification.open({
                    message: response.data.message,
                    placement: "topRight",
                    duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                    style: {
                        background: process.env.VUE_APP_SUCCESS_COLOR,
                    },
                });

            }).catch(error=>{
                this.disableButton = false;

                loader.hide();
                if(error?.response){

                    var response = (error.response);
                        
                    notification.open({
                        message: response.data.message,
                        placement: "topRight",
                        duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                        style: {
                        background: process.env.VUE_APP_WARNING_COLOR,
                        },
                    });
                    
                }else{
                    
                    notification.open({
                        message: 'Server Error',
                        placement: "topRight",
                        duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                        style: {
                        background: process.env.VUE_APP_WARNING_COLOR,
                        },
                    });
                }
            })
        }

        this.disableButton = false;
    },
    editData(record)
    {
        this.errors = []
        this.editData_ = record
    },
    clearFormData()
    {
      this.editActiveData = {
        team_name:'',
        team_status:1,
      }

      this.selectedFrom = [];
      this.selectedTo = [];
      this.optionsTo = [];
      this.employees = [...this.employeesCopy];

      this.errors = null
    },
    async updateTeamAction(){
        this.disableButton = true;
        this.errors = []

        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        let loader = this.$loading.show({
            container: this.$refs.formContainer,
            canCancel: false
        });

        const team_members = this.optionsTo.map(obj => obj.id) || [];

        const formData = new FormData();
        formData.append('team_name',this.editActiveData?.team_name);
        formData.append('team_status',this.editActiveData?.team_status);
        formData.append('team_members',team_members);
    

        if(this.editActiveData?.id && this.editActiveData?.id != null)
        {
            formData.append('id',this.editActiveData?.id);

            await axios.put('/team/'+this.editActiveData?.id, formData).then(response => {
                this.disableButton = false;
                notification.open({
                    message: response.data.message,
                    placement: "topRight",
                    duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                    style: {
                        background: process.env.VUE_APP_SUCCESS_COLOR,
                    },
                });

                this.$refs.modal_team_information_close.click();
                this.updatePerPage();
                this.clearFormData()
                

                loader.hide();

            }).catch(error =>{
                this.disableButton = false;

                loader.hide();
                if(error.response){

                    var response = (error.response);
                        
                    notification.open({
                        message: response.data.message,
                        placement: "topRight",
                        duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                        style: {
                        background: process.env.VUE_APP_WARNING_COLOR,
                        },
                    });
                    
                }else{
                    
                    notification.open({
                        message: 'Server Error',
                        placement: "topRight",
                        duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                        style: {
                        background: process.env.VUE_APP_WARNING_COLOR,
                        },
                    });
                }
            });
        }
        else
        {
            await axios.post('/team', formData).then(response => {
                this.disableButton = false;
            
            notification.open({
                message: response.data.message,
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                    background: process.env.VUE_APP_SUCCESS_COLOR,
                },
            });

            this.$refs.modal_team_information_close.click();
            this.updatePerPage();
            this.clearFormData()
            

            loader.hide();
            }).catch(error =>{
                this.disableButton = false;

            loader.hide();
            if(error.response){

                var response = (error.response);
                    
                notification.open({
                    message: response.data.message,
                    placement: "topRight",
                    duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                    style: {
                    background: process.env.VUE_APP_WARNING_COLOR,
                    },
                });
                
            }else{
                
                notification.open({
                    message: 'Server Error',
                    placement: "topRight",
                    duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                    style: {
                    background: process.env.VUE_APP_WARNING_COLOR,
                    },
                });
            }
            });
        }


        
    },
    async updatePerPage(params = null){

      if(params == null)
      {
        params = {
            params: { per_page: this.pagination.pageSize }
        };
      }

        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        let loader = this.$loading.show({
            container: this.$refs.formContainer,
            canCancel: false
        });

        

        axios.get('team',params).then(response =>{

           

            this.pagination.total = response.data.data.total;
            this.pagination.current = response.data.data.current_page;
            this.pagination.pageSize = response.data.data.per_page;
            
            this.data = response.data.data.data;

            loader.hide();

        }).catch(error =>{

            loader.hide();
            if(error.response){
                
                var response = (error.response);
                    
                notification.open({
                    message: response?.data?.message || 'Error',
                    placement: "topRight",
                    duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                    style: {
                    background: process.env.VUE_APP_WARNING_COLOR,
                    },
                });
                
            }else{
                
                notification.open({
                    message: 'Server Error',
                    placement: "topRight",
                    duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                    style: {
                    background: process.env.VUE_APP_WARNING_COLOR,
                    },
                });
            }
        });
      
    },

    async fetchEmployeeList(){
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;


      await axios.get('/employees/all').then((response)=>{
  
        
        this.employeesCopy = response?.data?.data || [];

        this.employees = [...this.employeesCopy];

      }).catch(error =>{
        console.log('error in fetching employee',error);
      })
    },

    moveRightAll() {
      this.optionsTo = this.optionsTo.concat(this.employees); // Add selected options to optionsTo
      this.employees = []; // Clear optionsFrom
    },
    moveRightSelected() {
      // Move selected options from optionsFrom to optionsTo
      const selectedValues = this.selectedFrom.slice();


      this.optionsTo = this.optionsTo.concat(
        this.employees.filter((option) => selectedValues.includes(option.id))
      );
      // Remove selected options from employees
      this.employees = this.employees.filter(
        (option) => !selectedValues.includes(option.id)
      );
      // Clear selectedFrom
      this.selectedFrom = [];
    },

    moveLeftSelected() {
      // Move selected options from optionsTo to employees
      const selectedValues = this.selectedTo.slice();
      this.employees = this.employees.concat(
        this.optionsTo.filter((option) => selectedValues.includes(option.id))
      );
      // Remove selected options from optionsTo
      this.optionsTo = this.optionsTo.filter(
        (option) => !selectedValues.includes(option.id)
      );
      // Clear selectedTo
      this.selectedTo = [];
    },
    moveLeftAll() {
      this.employees = this.employees.concat(this.optionsTo); // Add selected options back to optionsFrom
      this.optionsTo = [];
    },
  },
  watch:{
    editData_(newVal)
    {
        if(newVal != null)
        {
            this.selectedFrom = [];
            this.selectedTo = [];
            this.optionsTo = [];
            this.employees = [...this.employeesCopy];
            this.editActiveData = {
                team_name : newVal?.team_name || '',
                team_status : newVal?.team_status || '',
                id : newVal?.id || '',
            }

            if(newVal?.members.length)
            {
                const memberIds = newVal?.members.map(obj => obj.member_id);
                this.selectedFrom = memberIds;
                this.moveRightSelected();
            }
        }
        
    }
  },
  async created(){
    // this.$store.dispatch('userPermissions', { module: 'wages', action: 'write' }).then(response =>{
    //   this.hasEditPermission=null;
    //   this.$nextTick(()=>{
    //     this.hasEditPermission = response;
    //   })
    // })

    // this.$store.dispatch('userPermissions', { module: 'wages', action: 'delete' }).then(response =>{
    //   this.hasDeletePermission=null;
    //   this.$nextTick(()=>{
    //     this.hasDeletePermission = response;
    //   })
    // })
    await this.fetchEmployeeList();
    this.updatePerPage();
  }
  
  }
</script>